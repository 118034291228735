import { useState } from "react";
import Inference from "./Inference";
import "./Product.css";

function Product() {
  const [activeTab, setActiveTab] = useState("plan");

  const isActiveTab = (name) => name === activeTab;

  const renderTab = () => {
    switch(activeTab) {
      case "plan":
        return (
          <>
            <p>Build an action plan that contains the<br/> Dagger actions to build and deploy your application</p>
            <code># tsctl plan</code>
          </>
        );
      case "input": 
        return (
          <>
            <p>Generate a configuration schema<br/> to configure action variables</p>
            <code># tsctl config init</code>
          </>
        );
      case "run": 
        return (
          <>
            <p>Run the action plan <br/> using the configured inputs</p>
            <code># tsctl run</code>
          </>
        );
      default:
    }
  }

  return (
    <div id="product" className="Product">
      <div className="Bullets">
        <div className="Container">
          <div className="Bullet">
            <svg viewBox="0 0 511.656 511.656">
              <path d="m384.175 385.631c-7.534 0-14.655 1.786-20.977 4.943l-32.683-32.683c-2.812-2.813-6.628-4.394-10.606-4.394h-49.267v30h43.054l28.29 28.289c-15.77 30.507 7.693 68.57 42.191 68.11 62.438-2.366 62.427-91.904-.002-94.265z"/>
              <path d="m464.508 273.164c-20.745 0-38.39 13.478-44.671 32.133h-29.449l-27.739-27.74c-2.812-2.813-6.628-4.394-10.606-4.394h-81.4v30h75.188l27.739 27.74c2.812 2.813 6.628 4.394 10.606 4.394h35.662c17.611 49.89 90.754 38.537 91.805-15.001-.001-25.988-21.146-47.132-47.135-47.132z"/>
              <path d="m330.515 154.171 32.683-32.683c30.504 15.771 68.572-7.694 68.111-42.191 0-25.989-21.144-47.133-47.133-47.133-34.494-.458-57.964 37.606-42.19 68.111l-28.29 28.289h-43.054v30h49.267c3.978 0 7.793-1.58 10.606-4.393z"/>
              <path d="m362.648 234.504 27.739-27.74h29.449c17.604 49.883 90.754 38.543 91.805-15.001-1.056-53.551-74.211-64.875-91.805-14.999h-35.662c-3.979 0-7.794 1.581-10.606 4.394l-27.739 27.74h-75.188v30h81.4c3.98 0 7.795-1.581 10.607-4.394z"/>
              <path d="m183.375.031c-51.667-1.793-79.285 74.746-36.133 78.401 8.284-.001 15 6.715 15 14.999s-6.716 15-15 15c-22.977 0-42.459-15.236-48.884-36.133h-5.315c-31.577 0-57.267 25.689-57.267 57.267 0 21.615 17.585 39.2 39.2 39.2 19.866.726 19.878 29.268 0 30h-18.068c-75.887 2.888-75.829 111.673 0 114.533h18.067c19.879.741 19.869 29.265 0 30-21.615 0-39.2 17.585-39.2 39.2 0 31.577 25.69 57.267 57.267 57.267h5.315c6.426-20.898 25.907-36.133 48.884-36.133 8.284 0 15 6.716 15 15s-6.716 15-15 15c-11.653 0-21.133 9.48-21.133 21.133 2.87 75.857 111.666 75.855 114.533 0v-108.4c-1.526-39.979-58.825-39.942-60.333 0 0 8.284-6.716 15-15 15s-15-6.716-15-15c-.645-45.71 51.419-75.498 90.333-52.024v-76.618c-38.926 23.471-90.975-6.314-90.332-52.025-.001-8.283 6.715-14.999 14.999-14.999s15 6.716 15 15c1.517 39.971 58.827 39.947 60.333 0v-108.4c.001-31.579-25.689-57.268-57.266-57.268z"/>
            </svg>
            <h2>Rule Based AI</h2>
            <p>Powerful rule based AI automatically determines pipelines from source code</p>
          </div>
          <div className="Bullet">
            <svg viewBox="0 0 511.997 511.997">
              <path d="M506.76,242.828l-118.4-125.44c-7.277-7.718-19.424-8.07-27.142-0.787c-7.706,7.277-8.064,19.43-0.781,27.142
                l105.965,112.256L360.437,368.268c-7.283,7.712-6.925,19.859,0.781,27.142c3.712,3.501,8.454,5.235,13.178,5.235
                c5.101,0,10.195-2.022,13.965-6.01l118.4-125.446C513.742,261.785,513.742,250.226,506.76,242.828z"/>

              <path d="M151.566,368.262L45.608,255.999l105.958-112.262c7.277-7.712,6.925-19.866-0.787-27.142
                c-7.706-7.277-19.866-6.925-27.142,0.787l-118.4,125.44c-6.982,7.398-6.982,18.963,0,26.362L123.643,394.63
                c3.776,4,8.864,6.016,13.965,6.016c4.723,0,9.466-1.741,13.171-5.242C158.498,388.127,158.843,375.974,151.566,368.262z"/>

              <path d="M287.061,52.697c-10.477-1.587-20.282,5.606-21.882,16.083l-56.32,368.64c-1.6,10.483,5.6,20.282,16.083,21.882
                c0.986,0.147,1.958,0.218,2.925,0.218c9.325,0,17.504-6.803,18.957-16.301l56.32-368.64
                C304.744,64.095,297.544,54.297,287.061,52.697z"/>
            </svg>
            <h2>Pipelines As Code</h2>
            <p>Actions are built and run anywhere using <a href="https://dagger.io/">Dagger</a> pipelines as code</p>
          </div>
          <div className="Bullet">
            <svg viewBox="0 0 24 24">
              <path d="m9.207 6.562c1.278-1.127 2.956-1.812 4.793-1.812 4.001 0 7.25 3.249 7.25 7.25s-3.249 7.25-7.25 7.25c-1.837 0-3.515-.685-4.793-1.812-.31-.274-.785-.245-1.059.066-.274.31-.244.784.066 1.058 1.543 1.362 3.569 2.188 5.786 2.188 4.829 0 8.75-3.921 8.75-8.75s-3.921-8.75-8.75-8.75c-2.217 0-4.243.826-5.786 2.188-.31.274-.34.748-.066 1.058.274.311.749.34 1.059.066z"/><path d="m12.85 7.6v4c0 .199.079.39.22.53l3.2 3.2c.292.293.768.293 1.06 0 .293-.292.293-.768 0-1.06l-2.98-2.981v-3.689c0-.414-.336-.75-.75-.75s-.75.336-.75.75z"/><path d="m2 9.25h6c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-6c-.414 0-.75.336-.75.75s.336.75.75.75z"/><path d="m3 12.75h6c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-6c-.414 0-.75.336-.75.75s.336.75.75.75z"/><path d="m4 16.25h6c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-6c-.414 0-.75.336-.75.75s.336.75.75.75z"/>
            </svg>
            <h2>Agile CI/CD</h2>
            <p>Adopt new tools and DevOps practices without building pipeline code</p>
          </div>
          <div className="Bullet">
            <svg viewBox="0 0 512 512">
              <path d="M478.08,200.17l-28.46-5c-3.573-11.424-8.154-22.507-13.69-33.12l16.58-23.63c11.454-16.293,9.521-38.465-4.58-52.53
                L426.1,64.06c-14.065-14.101-36.237-16.034-52.53-4.58l-23.64,16.58c-10.613-5.536-21.696-10.117-33.12-13.69l-5-28.46
                C308.368,14.306,291.344,0.006,271.44,0h-30.88c-19.92-0.023-36.964,14.295-40.38,33.92l-5,28.45
                c-11.424,3.572-22.508,8.153-33.12,13.69l-23.63-16.58c-16.293-11.454-38.465-9.521-52.53,4.58L64.06,85.9
                c-14.101,14.065-16.034,36.237-4.58,52.53l16.57,23.63c-5.536,10.613-10.117,21.696-13.69,33.12l-28.45,5
                C14.289,203.6-0.023,220.644,0,240.56v30.88c0.002,19.911,14.308,36.942,33.92,40.38l28.45,5
                c3.572,11.424,8.153,22.508,13.69,33.12l-16.58,23.63c-11.454,16.293-9.521,38.465,4.58,52.53l21.84,21.84
                c14.065,14.101,36.237,16.034,52.53,4.58l23.63-16.57c10.612,5.537,21.696,10.119,33.12,13.69l5,28.46
                c3.424,19.617,20.467,33.924,40.38,33.9h30.88c19.916,0.023,36.96-14.289,40.38-33.91c1.243-7.072-3.483-13.812-10.555-15.055
                c-7.072-1.243-13.812,3.483-15.055,10.555c-1.258,7.174-7.487,12.408-14.77,12.41h-30.88c-7.287,0.003-13.522-5.232-14.78-12.41
                l-6.39-36.43c-0.866-4.967-4.526-8.985-9.39-10.31c-14.78-3.955-28.981-9.825-42.24-17.46c-4.388-2.542-9.854-2.3-14,0.62
                l-30.25,21.22c-5.965,4.181-14.071,3.472-19.22-1.68l-21.84-21.84c-5.152-5.149-5.861-13.255-1.68-19.22L102,358.25
                c2.92-4.146,3.162-9.612,0.62-14c-7.641-13.262-13.518-27.466-17.48-42.25c-1.308-4.884-5.33-8.566-10.31-9.44l-36.42-6.39
                c-7.159-1.255-12.388-7.462-12.41-14.73v-30.88c0.002-7.283,5.236-13.512,12.41-14.77l36.43-6.39c4.966-0.871,8.98-4.535,10.3-9.4
                c3.96-14.783,9.833-28.987,17.47-42.25c2.542-4.388,2.3-9.854-0.62-14l-21.22-30.24c-4.181-5.965-3.472-14.071,1.68-19.22
                l21.84-21.84c5.149-5.152,13.255-5.861,19.22-1.68L153.75,102c4.146,2.92,9.612,3.162,14,0.62
                c13.262-7.639,27.466-13.512,42.25-17.47c4.884-1.309,8.566-5.33,9.44-10.31l6.39-36.43c1.255-7.159,7.462-12.388,14.73-12.41
                h30.88c7.287-0.003,13.522,5.232,14.78,12.41l6.39,36.43c0.866,4.967,4.526,8.985,9.39,10.31c14.78,3.961,28.98,9.834,42.24,17.47
                c4.388,2.542,9.854,2.3,14-0.62l30.25-21.22c5.965-4.181,14.071-3.472,19.22,1.68l21.84,21.84
                c5.152,5.149,5.861,13.255,1.68,19.22L410,153.76c-2.92,4.146-3.162,9.612-0.62,14c7.635,13.26,13.508,27.46,17.47,42.24
                c1.312,4.88,5.333,8.558,10.31,9.43l36.43,6.39c7.163,1.256,12.393,7.468,12.41,14.74v30.88c0.003,7.287-5.232,13.522-12.41,14.78
                c-7.072,1.243-11.798,7.983-10.555,15.055c1.243,7.072,7.983,11.798,15.055,10.555c19.624-3.421,33.938-20.47,33.91-40.39v-30.88
                C512.003,220.646,497.695,203.609,478.08,200.17z"/>
              <path d="M475.07,391.53l-83.16-85.69c-10.874-11.145-16.322-26.493-14.91-42c5.968-68.273-44.539-128.457-112.812-134.425
                c-7.249-0.634-14.54-0.629-21.788,0.015c-5.68,0.462-11.017,2.904-15.08,6.9c-9.885,9.722-10.037,25.61-0.34,35.52l35.83,36.53
                c7.282,7.417,7.173,19.332-0.244,26.614c-0.002,0.002-0.004,0.004-0.006,0.006l-27.74,27.21c-7.421,7.275-19.333,7.158-26.61-0.26
                l-35.83-36.53c-9.722-9.885-25.61-10.037-35.52-0.34c-4.079,3.985-6.628,9.276-7.2,14.95
                c-7.372,68.135,41.887,129.346,110.022,136.718c7.239,0.783,14.533,0.928,21.798,0.432c15.498-1.112,30.707,4.609,41.63,15.66
                l83.11,85.63c5.001,5.153,13.232,5.276,18.385,0.275c5.153-5.001,5.276-13.232,0.275-18.385l-83.16-85.68
                c-16.246-16.503-38.899-25.071-62-23.45c-54.047,3.704-100.863-37.107-104.567-91.154c-0.341-4.978-0.302-9.974,0.117-14.946
                l34.36,35c8.307,8.514,19.675,13.35,31.57,13.43h0.44c11.73,0.036,23-4.561,31.36-12.79l27.74-27.21
                c17.67-17.334,17.943-45.709,0.61-63.38l-34.36-35c54.058-3.531,100.744,37.429,104.276,91.487
                c0.325,4.981,0.27,9.98-0.165,14.953c-2.071,23.038,6.04,45.83,22.2,62.38l83.11,85.64c5.001,5.153,13.232,5.276,18.385,0.275
                C479.948,404.914,480.071,396.683,475.07,391.53z"/>
            </svg>
            <h2>Config As Code</h2>
            <p>Manage pipeline credentials and parameters with <a href="https://configu.com/">Configu</a> configuration-as-code</p>
          </div>
        </div>
      </div>
      <div className="Commands">
        <div className="Container">
          <h1>Go from source to delivery with three commands</h1>
          <div className="Tabs">
            <div className="Picker">
              <span onClick={() => setActiveTab("plan")} data-selected={isActiveTab("plan")}>Plan</span>
              <span onClick={() => setActiveTab("input")} data-selected={isActiveTab("input")}>Input</span>
              <span onClick={() => setActiveTab("run")} data-selected={isActiveTab("run")}>Run</span>
            </div>
          </div>
          <div className="Details">
            {renderTab()}
          </div>
        </div>
      </div>
      <Inference/>
    </div>
  )
}

export default Product;