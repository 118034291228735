import "./Footer.css";

function Footer() {
  return (
    <div className="Footer">
      <div className="Container">
        <p>&copy; TruStacks 2023</p>
        <div className="Social">
          <a href="https://github.com/TruStacks/trustacks">
            <svg viewBox="0 0 24 24">
              <path d="m12 .5c-6.63 0-12 5.28-12 11.792 0 5.211 3.438 9.63 8.205 11.188.6.111.82-.254.82-.567 0-.28-.01-1.022-.015-2.005-3.338.711-4.042-1.582-4.042-1.582-.546-1.361-1.335-1.725-1.335-1.725-1.087-.731.084-.716.084-.716 1.205.082 1.838 1.215 1.838 1.215 1.07 1.803 2.809 1.282 3.495.981.108-.763.417-1.282.76-1.577-2.665-.295-5.466-1.309-5.466-5.827 0-1.287.465-2.339 1.235-3.164-.135-.298-.54-1.497.105-3.121 0 0 1.005-.316 3.3 1.209.96-.262 1.98-.392 3-.398 1.02.006 2.04.136 3 .398 2.28-1.525 3.285-1.209 3.285-1.209.645 1.624.24 2.823.12 3.121.765.825 1.23 1.877 1.23 3.164 0 4.53-2.805 5.527-5.475 5.817.42.354.81 1.077.81 2.182 0 1.578-.015 2.846-.015 3.229 0 .309.21.678.825.56 4.801-1.548 8.236-5.97 8.236-11.173 0-6.512-5.373-11.792-12-11.792z"/>
            </svg>
          </a>
          <a href="https://discord.gg/usgjQj7QTd">
            <svg viewBox="0 0 512 512">
              <path d="m433.43 93.222c-32.633-14.973-67.627-26.005-104.216-32.324-.666-.122-1.332.183-1.675.792-4.501 8.005-9.486 
                18.447-12.977 26.655-39.353-5.892-78.505-5.892-117.051 0-3.492-8.39-8.658-18.65-13.179-26.655-.343-.589-1.009-.894-1.675-.792-36.568 
                6.298-71.562 17.33-104.216 32.324-.283.122-.525.325-.686.589-66.376 99.165-84.56 195.893-75.64 291.421.04.467.303.914.666 1.199 
                43.793 32.161 86.215 51.685 127.848 64.627.666.203 1.372-.04 1.796-.589 9.848-13.449 
                18.627-27.63 26.154-42.543.444-.873.02-1.91-.888-2.255-13.925-5.282-27.184-11.723-39.939-19.036-1.009-.589-1.09-2.032-.161-2.723 
                2.684-2.011 5.369-4.104 7.932-6.217.464-.386 1.11-.467 1.655-.224 83.792 38.257 174.507 38.257 257.31 0 .545-.264 1.191-.182 1.675.203 
                2.564 2.113 5.248 4.226 7.952 6.237.928.691.867 2.134-.141 2.723-12.755 7.456-26.014 13.754-39.959 19.016-.908.345-1.312 1.402-.867 
                2.275 7.689 14.892 16.468 29.073 26.134 42.523.404.569 1.13.813 1.796.609 41.835-12.941 84.257-32.466 128.05-64.627.384-.284.626-.711.666-1.178 
                10.676-110.441-17.881-206.376-75.7-291.421-.14-.284-.382-.487-.664-.609zm-262.336 233.843c-25.227 0-46.014-23.16-46.014-51.604 0-28.443 
                20.383-51.604 46.014-51.604 25.831 0 46.417 23.364 46.013 51.604 0 28.444-20.384 51.604-46.013 51.604zm170.127 0c-25.226 
                0-46.013-23.16-46.013-51.604 0-28.443 20.383-51.604 46.013-51.604 25.832 0 46.417 23.364 46.014 51.604 0 28.444-20.181 51.604-46.014 51.604z"
              >
              </path>
            </svg>
          </a>
          <a href="https://twitter.com/TruStacks1">
            <svg viewBox="0 0 512 512">
              <path d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
                c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
                c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
                c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
                c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
                c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
                C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
                C480.224,136.96,497.728,118.496,512,97.248z"
                >      
              </path>
            </svg>
          </a>
        </div>
      </div>
      <div className="Underlay"></div>
    </div>
  )
}

export default Footer;