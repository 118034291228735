import './App.css';
import Footer from './Footer';
import Header from "./Header";
import Product from './Product';
import Pricing from './Pricing';

function App() {
  return (
    <div className="App">
      <Header />
      <Product />
      {/* <Pricing /> */}
      <Footer />
    </div>
  );
}

export default App;
