import logo from './logo.svg';
import "./Header.css";
import { useEffect } from 'react';

function Header() {
	function generateDot() {
		const header = document.getElementsByClassName("Header")[0]
		this.obj = document.createElement("div");
		this.obj.classList.add("DotBG");
		this.obj.style.top = (header.scrollHeight * Math.random()) + 'px';
		this.obj.style.left = (header.scrollWidth * Math.random()) + 'px';
		window.addEventListener("resize", () => {
			this.obj.style.top = (header.scrollHeight * Math.random()) + 'px';
			this.obj.style.left = (header.scrollWidth * Math.random()) + 'px';
		});
		this.size = Math.floor(5 * Math.random()) + Math.random();
		this.obj.style.height = this.size + 'px';
		this.obj.style.backgroundColor = (Math.random() > .5) ? "#897DBB" : "#76CABC";
		this.obj.style.width = this.size + 'px';
		let opacity = Math.random();
		let maxOpacity = .7;
		this.obj.style.opacity = (opacity < maxOpacity) ? opacity : maxOpacity;
		header.appendChild(this.obj);
  	}

	useEffect(() => {
		for(let i = 0 ; i < 100; i++ ){
			new generateDot();
		}
	});

	return (
		<div className="Header">
			<div className="Container">
				<div className="Nav">
					<a href="/"><img className="Brand" src={logo} alt="logo" /></a>
					<div className="Links">
						<a href="https://docs.trustacks.io">Docs</a>
						{/* <a className="EarlyAccess" href="#pricing">Early Access</a> */}
					</div>
				</div>
				<div className="Intro">
					<h1>Seriously Smart<br/> Software Delivery.</h1>
					<p>
					 	Its like GitOps for the entire pipeline. TruStacks assembles your favorite tools into delivery pipelines
						using your source code as declarative configuration.
					</p>
					<a href="https://docs.trustacks.io">Get Started</a>
				</div>
			</div>
		</div>
	)
}

export default Header;