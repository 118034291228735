import { useState } from "react";
import "./Inference.css";
import { render } from "@testing-library/react";

function Inference() {
  const files = [
    ".golangci.yml",
    "go.mod",
    "*_test.go",
    "cmd/*",
    "sonar-project.properties",
    "Containerfile",
    "trivy.yaml",
    "application.yaml",
  ];
  const actions = {
    "Lint the source using golangci-lint": [
      ".golangci.yml",
    ],
    "Run the test suite": [
      "go.mod",
      "*_test.go",
    ],
    "Build the application binary": [
      "go.mod",
      "cmd/*"
    ],
    "Run a code quality check using SonarQube": [
      "sonar-project.properties",
    ],
    "Build the container image": [
      "Containerfile",
    ],
    "Publish the container image": [
      "Containerfile",
    ],
    "Scan the container for security vulnerabilities using Trivy": [
      "trivy.yaml",
    ],
    "Sync the Argo CD application": [
      "application.yaml",
    ],
  }
  const [selectedSources, setSelectedSources] = useState([]);

  const sourceIsSelected = (name) => {
    return selectedSources.indexOf(name) !== -1;
  }

  const toggleAction = (name) => {
    if (sourceIsSelected(name)) {
      let sourceIndex;
      selectedSources.forEach((f, i) => {
        if (f === name) {
          sourceIndex = i;
        }
      })
      selectedSources.splice(sourceIndex, 1);
      setSelectedSources([...selectedSources]);
    } else {
      setSelectedSources(selectedSources.concat(name));
    }
  }

  const renderActions = () => {
    const render = [];
    for (const [key, value] of Object.entries(actions)) {
      let active = true;
      value.map((name) => {
        if (!sourceIsSelected(name)) {
          active = false;
        }
      });
      render.push({name: key, active: active});
    }

    return (
      <div className="Actions">
        {render.map((action, index) => (
          <span data-active={action.active} key={index}>
            <svg viewBox="0 0 512 512">
              <path d="M256,0C114.616,0,0,114.616,0,256s114.616,256,256,256c141.394,0,256-114.616,256-256S397.394,0,256,0z M256,460.8
                c-112.927,0-204.8-91.873-204.8-204.8S143.073,51.2,256,51.2S460.8,143.073,460.8,256S368.927,460.8,256,460.8z"/>
              <path d="M349.112,238.08l-124.15-71.68c-17.07-9.851-31.037-1.792-31.037,17.92v143.36c0,19.712,13.967,27.781,31.037,17.92
                l124.15-71.68C366.182,264.069,366.182,247.931,349.112,238.08z"/>
            </svg>
            {action.name}
          </span>
        ))}
      </div>
    )
  }

  return (
    <div className="Inference">
      <div className="Container">
        <h1>Toggle sources below to simulate action selection.</h1>
        <div className="Flex">
          {files.map((name, index) => (
            <button onClick={() => toggleAction(name)} key={index} data-selected={sourceIsSelected(name)} className="File">
              {(sourceIsSelected(name)) 
                ?
                  <svg viewBox="0 0 48 48">
                    <path fill="#76CABC" d="m24 0a24 24 0 1 0 24 24 24 24 0 0 0 -24-24zm15.65 16.49-19 19a2.75 2.75 0 0 1 -3.94 0l-8.36-8.43a2.79 2.79 0 0 1 3.94-3.94l6.42 6.42 17-17a2.79 2.79 0 0 1 3.94 3.94z"/>
                  </svg>
                :
                  <svg viewBox="0 0 1707 1707">
                    <path d="m1457 250c-155-154-368-250-604-250-235 0-449 96-603 250s-250 368-250 603c0 236 96 449 250 604 154 154 368 250 603 250 236 0 449-96 604-250 154-155 250-368 250-604 0-235-96-449-250-603z" fill="#777"/>
                    <path d="m418 694h870c44 0 84 18 113 46 29 29 47 69 47 113s-18 84-47 113-69 47-113 47h-870c-44 0-84-18-113-47s-47-69-47-113 18-84 47-113c29-28 69-46 113-46z" fill="#111"/>
                  </svg>
              }
              {name}
            </button>
          ))}
        </div>
        {renderActions()}
      </div>
    </div>
  )
}

export default Inference;